<template>
  <div>
  <v-tooltip left open-delay="500" class="theme-component">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="openDialog()"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-crown</v-icon>
      </v-btn>
    </template>
    <span>
      {{ $localizationService.localize("master.door_tooltip") }}
    </span>
  </v-tooltip>
  <v-dialog v-model="open" max-width="500px">
    <v-card v-if="open" :loading="showLoadingAndBlockUi">
      <v-card-title>
          <span class="headline">
          {{
              $localizationService.localize("master.dialog.headline")
          }}
          </span>
      </v-card-title>

      <v-card-text>
        <v-container>
        <v-row>
            <v-col>
              <v-autocomplete
                class="mb-6"
                :label="
                  $localizationService.localize(
                    'master.dialog.company_selector_header'
                  )
                "
                v-model="selectedCompany"
                item-text="name"
                return-object
                :items="companies"
                :loading="!companies.length"
                hide-details
                :disabled="showLoadingAndBlockUi"
              ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
              <v-autocomplete
                class="mb-6"
                :label="
                  $localizationService.localize(
                    'master.dialog.user_selector_header'
                  )
                "
                v-model="selectedUser"
                item-text="name"
                item-value="id"
                return-object
                :items="users"
                :loading="usersLoading"
                hide-details
                :disabled="showLoadingAndBlockUi"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{item.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{item.email}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
        </v-row>
        </v-container>
      </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDialog" :disabled="showLoadingAndBlockUi">
              {{ $localizationService.localize("btn.cancel") }}
            </v-btn>
            <v-btn
                class="master-door-go-button"
                text
                @click="go()"
                :disabled="!selectedCompany || !selectedUser.id || showLoadingAndBlockUi"
            >
            {{ $localizationService.localize("master.dialog.log_in_as") }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import Vue from "vue";
import { mapState } from "vuex";
import { bodyWithAuthHeader, handleHttpError } from "../utils/utils";

export default {
  name: "MasterDoor",

  data: () => ({
    open: false,
    selectedCompany: undefined,
    selectedUser: {},
    companies: [],
    users: [],
    usersLoading: false,
    showLoadingAndBlockUi: false,
  }),

  watch: {

    async selectedCompany(newCompany) {
      this.users = [];

      if (newCompany) {
        const body = await bodyWithAuthHeader();
        this.usersLoading = true;
        try {
          this.users = await API.get("core", `/users/of_company/${newCompany.id}`, body);
        } catch (e) {
          Vue.toasted.error(error);
        } finally {
          this.usersLoading = false;
        }
      }
    },
  },

  computed: {
    ...mapState("userModule", ["user"]),
  },

  methods: {

    async go() {
      try {
        await this.safeGo();
      } catch (e) {
        handleHttpError(e);
      } finally {
        this.showLoadingAndBlockUi = false;
      }
    },

    async safeGo() {
      this.showLoadingAndBlockUi = true;

      const body = await bodyWithAuthHeader();
      const data = await API.get("core", `/users/wolf_in_sheeps_clothing/${this.selectedUser.id}`, body);

      const cognitoKeys = Object.keys(localStorage).filter((it) => it.startsWith("CognitoIdentityServiceProvider"));

      const originalIdTokenKey = cognitoKeys.find((it) => it.endsWith("idToken"));
      const originalRefreshTokenKey = cognitoKeys.find((it) => it.endsWith("refreshToken"));
      const originalLastAuthUserKey = cognitoKeys.find((it) => it.endsWith("LastAuthUser"));

      localStorage.setItem("original_cognito_id_token", localStorage.getItem(originalIdTokenKey));
      localStorage.setItem("original_cognito_refresh_token", localStorage.getItem(originalRefreshTokenKey));
      localStorage.setItem("original_cognito_last_auth_user", localStorage.getItem(originalLastAuthUserKey));

      const idTokenKey = originalIdTokenKey.replace(this.user.cognitoUsername, data.lastAuthUser);
      const refreshTokenKey = originalRefreshTokenKey.replace(this.user.cognitoUsername, data.lastAuthUser);
      const lastAuthUserKey = originalLastAuthUserKey.replace(this.user.cognitoUsername, data.lastAuthUser);

      cognitoKeys.forEach((it) => localStorage.removeItem(it));

      localStorage.setItem(idTokenKey, data.idToken);
      localStorage.setItem(refreshTokenKey, data.refreshToken);
      localStorage.setItem(lastAuthUserKey, data.lastAuthUser);

      window.location.reload();
    },

    async openDialog() {
      this.open = true;
      this.companies = [];
      this.selectedCompany = undefined;
      this.showLoadingAndBlockUi = false;

      const body = await bodyWithAuthHeader();
      this.companies = await API.get("core", "/company/all", body);
    },

    closeDialog() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss">
.master-door-go-button {

  color: #FFD700 !important;
}
</style>
