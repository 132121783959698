var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{staticClass:"theme-component",attrs:{"left":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-crown")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$localizationService.localize("master.door_tooltip"))+" ")])]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.open)?_c('v-card',{attrs:{"loading":_vm.showLoadingAndBlockUi}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("master.dialog.headline"))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"mb-6",attrs:{"label":_vm.$localizationService.localize(
                  'master.dialog.company_selector_header'
                ),"item-text":"name","return-object":"","items":_vm.companies,"loading":!_vm.companies.length,"hide-details":"","disabled":_vm.showLoadingAndBlockUi},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"mb-6",attrs:{"label":_vm.$localizationService.localize(
                  'master.dialog.user_selector_header'
                ),"item-text":"name","item-value":"id","return-object":"","items":_vm.users,"loading":_vm.usersLoading,"hide-details":"","disabled":_vm.showLoadingAndBlockUi},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.email)+" ")])],1)]}}],null,false,1667027219),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.showLoadingAndBlockUi},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("btn.cancel"))+" ")]),_c('v-btn',{staticClass:"master-door-go-button",attrs:{"text":"","disabled":!_vm.selectedCompany || !_vm.selectedUser.id || _vm.showLoadingAndBlockUi},on:{"click":function($event){return _vm.go()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("master.dialog.log_in_as"))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }